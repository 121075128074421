// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// components
import { usePage } from "@inertiajs/react";
import { CustomAvatar } from "@/Components/custom-avatar";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
    const {
        auth: { user },
    } = usePage().props;

    return (
        <Link
            to={PATH_DASHBOARD.user.account}
            component={RouterLink}
            underline="none"
            color="inherit"
        >
            <StyledRoot>
                <CustomAvatar
                    src={user?.photoURL}
                    alt={user?.displayName}
                    name={user?.displayName}
                />

                <Box sx={{ ml: 2, minWidth: 0 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.displayName}
                    </Typography>

                    <Typography
                        variant="body2"
                        noWrap
                        sx={{ color: "text.secondary" }}
                    >
                        {user?.role}
                    </Typography>
                </Box>
            </StyledRoot>
        </Link>
    );
}
