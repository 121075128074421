import { useEffect, useRef, useState, useCallback } from "react";
import {
    Stack,
    AppBar,
    Toolbar,
    Link as MuiLink,
    Typography,
    Button,
    Container,
    Box,
    IconButton,
    debounce,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { bgBlur } from "@/utils/cssStyles";
import useResponsive from "@/hooks/useResponsive";
import Image from "@/Components/image/Image";
import { Link, router } from "@inertiajs/react";
import Iconify from "@/Components/iconify/Iconify";
import AccountPopover from "./AccountPopover";
import MenuPopover from "@/Components/menu-popover/MenuPopover";
import { HEADER } from "@/config";
import { IconButtonAnimate } from "@/Components/animate";
import React from "react";
import NotificationsPopover from "./NotificationsPopover";
import palette from "@/theme/palette";

const StyleMenuPop = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(3),
    "& .nav-link": {
        fontSize: "14px",
        color: "black",
    },
    "& .nav-link:hover": {
        fontWeight: "bold",
        color: "#012970",
    },
    "& .nav-link.active": {
        color: "#1977cc",
        fontWeight: "bold",
        borderRight: "1px solid",
        borderWidth: "3px",
        borderColor: "#1977cc",
    },
}));

const Header = ({ user, notifications }) => {
    const theme = useTheme();
    const currentRoute = route().current();
    const homeRoute = user ? "home" : "/";
    const contactInfoRef = useRef(null);
    const [openPosted, setOpenPosted] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isMobile = useResponsive("down", "sm");
    const isTablet = useResponsive("down", "md");
    const isDesktop = useResponsive("up", "lg");

    console.log(currentRoute);

    const handleDrawerOpen = useCallback(() => {
        setDrawerOpen(true);
    }, []);

    const handleDrawerClose = useCallback(() => {
        setDrawerOpen(false);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (contactInfoRef.current) {
                const { top, bottom } =
                    contactInfoRef.current.getBoundingClientRect();
                const windowHeight =
                    window.innerHeight || document.documentElement.clientHeight;
                contactInfoRef.current.style.height =
                    bottom < 0 || top > windowHeight ? 0 : "40px";
            }
        };

        const debouncedHandleScroll = debounce(handleScroll, 200);

        window.addEventListener("scroll", debouncedHandleScroll);

        return () => {
            window.removeEventListener("scroll", debouncedHandleScroll);
        };
    }, []);

    const handleOpenPosted = (event) => {
        setOpenPosted(event.currentTarget);
    };

    const handleClosePosted = () => {
        setOpenPosted(null);
    };

    const handleOpenInbox = (event) => {
        // setOpenInbox(event.currentTarget);
        router.visit(route("messaging.inbox"));
    };

    const renderContent = (
        <React.Fragment>
            <Stack direction={"row"} spacing={3}>
                <MuiLink
                    component={Link}
                    href={user != null ? "/home" : "/"}
                    style={{ textDecoration: "none" }}
                >
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                        >
                            <Image
                                src="/assets/logo/upcare-logo.png"
                                sx={{ width: 100 }}
                            />
                        </Stack>
                    </Stack>
                </MuiLink>
            </Stack>

            <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 1, sm: 3 }}
            >
                {user && (
                    <React.Fragment>
                        {isMobile || isTablet ? (
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                sx={{ width: "100%" }}
                            >
                                <Stack direction={"row"}>
                                    {user && (
                                        <AccountPopover
                                            notifications={notifications}
                                        />
                                    )}
                                    <Stack>
                                        <IconButton
                                            onClick={handleDrawerOpen}
                                            sx={{
                                                display: "block",
                                                [theme.breakpoints.up("md")]: {
                                                    display: "none",
                                                },
                                            }}
                                        >
                                            <Iconify
                                                icon="material-symbols:menu"
                                                sx={{
                                                    height: "32px",
                                                    width: "32px",
                                                    color: "#012970",
                                                }}
                                            />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack
                                flexGrow={1}
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={{ xs: 2, sm: 3 }}
                                className="navbar"
                                fontWeight={600}
                            >
                                {user && (
                                    <React.Fragment>
                                        <MuiLink
                                            component={Link}
                                            href={
                                                user ? route("dashboard") : "/"
                                            }
                                            underline="none"
                                            className={`nav-link scrollto ${
                                                currentRoute === "dashboard"
                                                    ? "active"
                                                    : ""
                                            }`}
                                        >
                                            Dashboard
                                        </MuiLink>
                                    </React.Fragment>
                                )}
                                <MuiLink
                                    component={Link}
                                    href={user ? route("home") : "/"}
                                    underline="none"
                                    className={`nav-link scrollto ${
                                        currentRoute === "home" ? "active" : ""
                                    }`}
                                >
                                    Home
                                </MuiLink>

                                <MuiLink
                                    component={Link}
                                    href={route("feed.index")}
                                    underline="none"
                                    className={`nav-link scrollto ${
                                        currentRoute === "feed.index"
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    Feeds
                                </MuiLink>

                                {user?.role !== "organization" &&
                                user?.role !== "employer" ? (
                                    <MuiLink
                                        component={Link}
                                        href={route("job.jobList")}
                                        underline="none"
                                        className={`nav-link scrollto ${
                                            currentRoute === "job.jobList"
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        Find Jobs
                                    </MuiLink>
                                ) : (
                                    <IconButtonAnimate
                                        onClick={handleOpenPosted}
                                        sx={{ p: 0 }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                color: "primary",
                                            }}
                                        >
                                            Posted Jobs
                                        </Typography>
                                        <Iconify icon="mdi:chevron-down" />
                                    </IconButtonAnimate>
                                )}
                                <Stack direction="row">
                                    {user.role === "user" && (
                                        <Button
                                            variant="contained"
                                            startIcon={
                                                <Iconify icon="mdi:form-outline" />
                                            }
                                            onClick={() => {
                                                router.visit(
                                                    route(
                                                        "application.listApplications"
                                                    )
                                                );
                                            }}
                                        >
                                            Applications
                                        </Button>
                                    )}
                                    <IconButtonAnimate
                                        color="primary"
                                        onClick={handleOpenInbox}
                                        sx={{ width: 40, height: 40 }}
                                    >
                                        <Iconify
                                            icon={"mdi:chat-processing"}
                                            sx={{ color: "#001c4e", mt: 0.5 }}
                                        />
                                    </IconButtonAnimate>
                                    <NotificationsPopover
                                        notifications={notifications}
                                    />
                                </Stack>
                                {/* <MessagesPopover /> */}
                                <AccountPopover notifications={notifications} />
                            </Stack>
                        )}
                    </React.Fragment>
                )}

                {!user && (
                    <Stack direction={"row"} spacing={1}>
                        <Button
                            className="login-btn"
                            variant="contained"
                            LinkComponent={Link}
                            href={route("login")}
                        >
                            <Box component="span" sx={{ color: "white" }}>
                                Login
                            </Box>
                        </Button>
                        <Button
                            className="login-btn"
                            variant="outlined"
                            LinkComponent={Link}
                            href={route("register")}
                        >
                            <Box component="span">Sign up</Box>
                        </Button>
                    </Stack>
                )}
            </Stack>
            {!isDesktop && (
                <IconButton
                    onClick={() => {}}
                    sx={{ mr: 1, color: "text.primary" }}
                >
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>
            )}

            {openPosted && (
                <MenuPopover
                    open={openPosted}
                    arrow={"top-left"}
                    onClose={handleClosePosted}
                    sx={{ width: 200, p: 0, mt: 1.5 }}
                >
                    <StyleMenuPop spacing={2}>
                        <MuiLink
                            href={route("job.postedJobs")}
                            underline="none"
                            className={`nav-link ${
                                currentRoute === "job.postedJobs"
                                    ? "active"
                                    : ""
                            }`}
                        >
                            Jobs
                        </MuiLink>
                        <MuiLink
                            href={route("job.employees")}
                            underline="none"
                            className={`nav-link ${
                                currentRoute === "job.employees" ? "active" : ""
                            }`}
                        >
                            Hired Applicants
                        </MuiLink>
                    </StyleMenuPop>
                </MenuPopover>
            )}
        </React.Fragment>
    );

    return (
        <Box sx={{ backgroundColor: "white" }}>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: "none",
                    height: HEADER.H_MOBILE,
                    zIndex: theme.zIndex.appBar + 1,
                    ...bgBlur({
                        color: theme.palette.background.default,
                    }),
                    transition: theme.transitions.create(["height"], {
                        duration: theme.transitions.duration.shorter,
                    }),
                    py: 1,
                    width: 1,
                    bgcolor: "background.default",
                    ...(isDesktop && {
                        height: "auto",
                    }),
                }}
            >
                <Container maxWidth="lg" sx={{ padding: 0 }}>
                    <Toolbar
                        sx={{
                            display: "flex",
                        }}
                    >
                        {renderContent}
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
};

export default Header;
