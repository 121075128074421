import { Stack, Box, Typography } from "@mui/material";

// ----------------------------------------------------------------------

export default function NavDocs() {
    return (
        <Stack
            spacing={3}
            sx={{
                px: 5,
                pb: 5,
                mt: 10,
                width: 1,
                display: "block",
                textAlign: "center",
            }}
        >
            <Typography variant="subtitle2" color="primary">
                Designed by Infinity Hub
            </Typography>
            {/* <Box component="img" src="/storage/assets/illustrations/illustration_docs.svg" /> */}
        </Stack>
    );
}
